@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');


/** ***** VARIABLES ***** **/

:root {
    --color-primary: var(--bs-color-primary, #3880ff);
    --color-secondary: var(--bs-color-medium, #989aa2);
    --color-info: var(--bs-color-secondary, #3dc2ff);
    --color-dark: var(--bs-color-dark, #222428);
    --color-light: var(--bs-color-light, #f4f5f8);
    --color-light-teint: #e5e5f7;
}



/** ***** TYPOGRAPHY ***** **/

body {
    font-family: 'Montserrat', sans-serif;
}

a, a:visited {
    text-decoration: none;
    color: inherit;
}

.image-copyright {
    position: absolute;
    padding: 5px;
    font-size: 10px;
}



/** ***** PAGE STYLE ***** **/

.page {
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}



/** ***** GENERIC CLASSES ***** **/

.flex-auto {
    flex: auto;
}

.align-item-center {
    align-items: center;
}



/** ***** TOP MENU ***** **/

.navbar-toggler.border-0:focus {
    box-shadow: none !important;
}



/** ***** MAIN SLIDER ***** **/

.carousel-item > img {
    height: 70vh;
    object-fit: cover;
}

.carousel-item > .carousel-caption {
    background-color: #2224289e;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.carousel-item > .carousel-caption > div {
    justify-content: center;
    height: -webkit-fill-available;
    text-align: left;
}

.slide .title {
    font-size: 4rem;
    font-weight: 500;
}

.slide .description {
    font-size: 3rem;
    font-weight: 300;
}

.carousel-indicators > button {
    width: 20px !important;
    height: 20px !important;
    border-radius: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .slide .title {
        font-size: 3rem;
    }
    .slide .description {
        font-size: 2.25rem;
    }
}

@media (max-width: 767px) {
    .carousel-item > .carousel-caption {
        display: block !important;
    }
    .slide .title {
        font-size: 2rem;
    }
    .slide .description {
        font-size: 1.5rem;
    }
}




/** ***** BANNER SECTION ***** **/

section#banner > div > div > h2 {
    font-size: 3rem;
    font-weight: 300;
    text-transform: uppercase;
}

section#banner > div > div > h3 {
    font-weight: 300;
}



/** ***** MISSION SECTION ***** **/

section#mission {
    background: linear-gradient(30deg, var(--color-primary) 12%, transparent 12.5%, transparent 87%, var(--color-primary) 87.5%, var(--color-primary)), linear-gradient(150deg, var(--color-primary) 12%, transparent 12.5%, transparent 87%, var(--color-primary) 87.5%, var(--color-primary)), linear-gradient(30deg, var(--color-primary) 12%, transparent 12.5%, transparent 87%, var(--color-primary) 87.5%, var(--color-primary)), linear-gradient(150deg, var(--color-primary) 12%, transparent 12.5%, transparent 87%, var(--color-primary) 87.5%, var(--color-primary)), linear-gradient(60deg, var(--color-info) 25%, transparent 25.5%, transparent 75%, var(--color-info) 75%, var(--color-info)), linear-gradient(60deg, var(--color-info) 25%, transparent 25.5%, transparent 75%, var(--color-info) 75%, var(--color-info)) var(--color-light-teint);
}

section#mission .card.card-body {
    background-color: #FFFFFFB3;
    font-weight: 500;
}



/** ***** SERVICES SECTION ***** **/

#services > .row > div:nth-child(1) {
    order: 2;
}
#services > .row > div:nth-child(2) {
    order: 1;
}
#services > .row > div:nth-child(3) {
    order: 3;
}

#services .card-title {
    font-size: calc(1.325rem + .9vw);
}
#services .card-text {
    font-size: calc(.8rem + .2vw);
    line-height: 1.5rem;
    font-weight: 500;
}

@media (min-width: 1200px) {
    #services .card-title {
        font-size: 2rem;
    }
    #services .card-text {
        font-size: 1rem;
    }
}
@media (min-width: 992px) {
    #services > .row > div:nth-child(1) {
        transform: scale(1.2);
    }
    #services > .row > div:nth-child(2) {
        transform: scale(0.9);
    }
    #services > .row > div:nth-child(3) {
        transform: scale(0.9);
    }
}
/* Mobile */
@media (max-width: 767px) {
    #services > .row > div:nth-child(1) {
        order: 1;
    }
    #services > .row > div:nth-child(2) {
        order: 2;
    }
    #services > .row > div:nth-child(3) {
        order: 3;
    }

    #services .card-text {
        font-size: calc(.8rem + .4vw);
    }
}

#services .card .image-copyright {
    right: 0;
    top: 0;
}




/** ***** SUB SERVICES ***** **/

#sub-services .card {
    overflow-x: hidden;
}
#sub-services .card .card-body {
    width: calc(75% - 80px);
    transition: width 300ms;
}
#sub-services .card .card-button {
    margin-right: -80px;
    transition: margin-right 300ms;
}
#sub-services .card:hover .card-body,
#sub-services .card:active .card-body {
    width: calc(75% - 160px);
}
#sub-services .card:hover .card-button,
#sub-services .card:active .card-button {
    margin-right: 0;
}
#sub-services .card:hover .card-button > i,
#sub-services .card:active .card-button > i {
    animation: rotation-1turns 300ms;
}
@keyframes rotation-1turns {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(3turn);
    }
}
#sub-services .card .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#sub-services .card .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
#sub-services .card .image-copyright {
    top: 0;
    left: 0;
    padding: 1px 2.5px;

    width: 25%;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#sub-services .card .card-img-top {
    object-fit: cover;
}

#sub-services .card-title {
    font-size: calc(1.125rem + .4vw);
}
@media (min-width: 1200px) {
    #sub-services .card-title {
        font-size: 1.325rem;
    }
}




/** ***** CONTACT SECTION ***** **/

section#contact {
    background: linear-gradient(15deg, #b1b1b1 20% 30%, #ff8bf9 30% 40%, transparent 50% 20%), linear-gradient(120deg, #5eccff 20% 30%, #5ccfe4 30% 40%, transparent 50% 20%), linear-gradient(-120deg, #ffa9ec 20% 30%, #ff77f4 30% 80%, transparent 50% 20%);
}

.form input, .form textarea, .form select {
    background-color: transparent;
}



/** *****  VALIDATION BUTTON  ***** **/

button[type="submit"] > i {
    animation: rotation-infinite 800ms infinite;
}

@keyframes rotation-infinite {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1turn);
    }
}